var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"popup_sub_container"},[_c('h2',[_vm._v("VIEW / EDIT BRANCH")]),_c('i',{staticClass:"closeme",on:{"click":_vm.closeModal}},[_vm._v("X")]),(_vm.unit_id)?_c('section',[_c('div',{staticClass:"jobs_table sp"},[_c('div',{},[_c('ValidationObserver',{ref:"observer",attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('section',[_c('div',{staticClass:"form_section1"},[_c('table',[_c('tr',[_c('td',{attrs:{"colspan":"3"}},[_c('div',{staticClass:"colspan_flex_adj"},[_c('ValidationProvider',{attrs:{"name":"name","rules":"required|max:30","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"label_and_element_wrapper"},[_c('label',[_vm._v("\n                        Branch Name\n                        "),_c('span',[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.unit.name),expression:"unit.name"}],class:errors[0] ? 'warning-border':'',attrs:{"type":"text","placeholder":"Name Character Limit 30","maxlength":_vm.max,"disabled":!_vm.is_editable},domProps:{"value":(_vm.unit.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.unit, "name", $event.target.value)}}})])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"code","rules":"required|max:30","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"label_and_element_wrapper"},[_c('label',[_vm._v("\n                        Branch Code\n                        "),_c('span',[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.unit.branch_id),expression:"unit.branch_id"}],class:errors[0] ? 'warning-border':'',attrs:{"type":"text","placeholder":"Code Character Limit 30","maxlength":_vm.max,"disabled":!_vm.is_editable},domProps:{"value":(_vm.unit.branch_id)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.unit, "branch_id", $event.target.value)}}})])]}}],null,true)})],1)])]),_c('tr',[_c('td',[_c('ValidationProvider',{attrs:{"name":"hub","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"label_and_element_wrapper"},[_c('label',[_vm._v("\n                      Hub/Mailroom\n                      "),_c('span',[_vm._v("*")])]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.unit.division_id),expression:"unit.division_id"}],class:errors[0] ? 'warning-border':'',attrs:{"disabled":!_vm.is_editable},on:{"click":_vm.fetchDivision,"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.unit, "division_id", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Select")]),_vm._l((_vm.divisions),function(division,i){return _c('option',{key:'division'+i,domProps:{"value":division.id}},[_vm._v(_vm._s(division.name))])})],2)])]}}],null,true)})],1),_c('td',[_c('ValidationProvider',{attrs:{"name":"cost","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"label_and_element_wrapper"},[_c('label',[_vm._v("\n                      Cost Center\n                      "),_c('span',[_vm._v("*")])]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.unit.cost_center_id),expression:"unit.cost_center_id"}],class:errors[0] ? 'warning-border':'',attrs:{"disabled":!_vm.is_editable},on:{"click":_vm.fetchCostCenter,"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.unit, "cost_center_id", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Select")]),_vm._l((_vm.cost_centers),function(cost,i){return _c('option',{key:'cost'+i,domProps:{"value":cost.id}},[_vm._v(_vm._s(cost.name))])})],2)])]}}],null,true)})],1)]),_c('tr',[_c('td',{attrs:{"colspan":"3"}},[_c('div',{staticClass:"colspan_flex_adj"},[_c('ValidationProvider',{attrs:{"name":"address_1","rules":"required|max:60","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"label_and_element_wrapper"},[_c('label',[_vm._v("\n                        Address 1\n                        "),_c('span',[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.unit.address_1),expression:"unit.address_1"}],class:errors[0] ? 'warning-border':'',attrs:{"type":"text","placeholder":"Address 1 Character Limit 60","maxlength":_vm.max2,"disabled":!_vm.is_editable},domProps:{"value":(_vm.unit.address_1)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.unit, "address_1", $event.target.value)}}})])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"address_2","rules":"required|max:60","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"label_and_element_wrapper"},[_c('label',[_vm._v("\n                        Address 2\n                        "),_c('span',[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.unit.address_2),expression:"unit.address_2"}],class:errors[0] ? 'warning-border':'',attrs:{"type":"text","placeholder":"Address 2 Character Limit 60","maxlength":_vm.max2,"disabled":!_vm.is_editable},domProps:{"value":(_vm.unit.address_2)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.unit, "address_2", $event.target.value)}}})])]}}],null,true)})],1)])]),_c('tr',[_c('td',[_c('div',{staticClass:"label_and_element_wrapper"},[_c('label',[_vm._v("\n                      Suburb\n                    ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.unit.suburb),expression:"unit.suburb"}],attrs:{"type":"text","placeholder":"Suburb Character Limit 30","maxlength":_vm.max,"disabled":!_vm.is_editable},domProps:{"value":(_vm.unit.suburb)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.unit, "suburb", $event.target.value)}}})])]),_c('td',[_c('ValidationProvider',{attrs:{"name":"city","rules":"required|max:30","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"label_and_element_wrapper"},[_c('label',[_vm._v("\n                      City\n                      "),_c('span',[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.unit.city),expression:"unit.city"}],class:errors[0] ? 'warning-border':'',attrs:{"type":"text","placeholder":"City Character Limit 30","maxlength":_vm.max,"disabled":!_vm.is_editable},domProps:{"value":(_vm.unit.city)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.unit, "city", $event.target.value)}}})])]}}],null,true)})],1),_c('td',[_c('ValidationProvider',{attrs:{"name":"state","rules":"required|max:30","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"label_and_element_wrapper"},[_c('label',[_vm._v("\n                      State\n                      "),_c('span',[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.unit.state),expression:"unit.state"}],class:errors[0] ? 'warning-border':'',attrs:{"type":"text","placeholder":"State Character Limit 30","maxlength":_vm.max,"disabled":!_vm.is_editable},domProps:{"value":(_vm.unit.state)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.unit, "state", $event.target.value)}}})])]}}],null,true)})],1)]),_c('tr',[_c('td',[_c('ValidationProvider',{attrs:{"name":"country","rules":"required|max:63","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"label_and_element_wrapper"},[_c('label',[_vm._v("\n                      Country\n                      "),_c('span',[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.unit.country),expression:"unit.country"}],class:errors[0] ? 'warning-border':'',attrs:{"type":"text","placeholder":"Country Character Limit 30","maxlength":_vm.max,"disabled":!_vm.is_editable},domProps:{"value":(_vm.unit.country)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.unit, "country", $event.target.value)}}})])]}}],null,true)})],1),_c('td',[_c('ValidationProvider',{attrs:{"name":"postal_code","rules":"required|max:10","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"label_and_element_wrapper"},[_c('label',[_vm._v("\n                      Postal Code\n                      "),_c('span',[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.unit.postal_code),expression:"unit.postal_code"}],class:errors[0] ? 'warning-border':'',attrs:{"type":"text","placeholder":"Postal Code Character Limit 10","maxlength":_vm.max1,"disabled":!_vm.is_editable},domProps:{"value":(_vm.unit.postal_code)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.unit, "postal_code", $event.target.value)}}})])]}}],null,true)})],1),_c('td',[_c('ValidationProvider',{attrs:{"name":"phone","rules":"required|max:20","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"label_and_element_wrapper"},[_c('label',[_vm._v("\n                      Phone Number\n                      "),_c('span',[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.unit.phone),expression:"unit.phone"}],class:errors[0] ? 'warning-border':'',attrs:{"type":"text","placeholder":"Phone Character Limit 20","maxlength":_vm.max3,"disabled":!_vm.is_editable},domProps:{"value":(_vm.unit.phone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.unit, "phone", $event.target.value)}}})])]}}],null,true)})],1)])])])])]}}],null,false,2293935638)}),_c('section',{staticClass:"centered"},[_c('a',{staticClass:"link_bt bt_cancel",on:{"click":_vm.closeModal}},[_vm._v("CANCEL")]),(_vm.is_editable)?_c('a',{staticClass:"link_bt bt_save",attrs:{"disabled":_vm.update_btn.disabled},on:{"click":_vm.updateBranch}},[_vm._v(_vm._s(_vm.update_btn.value))]):_vm._e()])],1)])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }